<template>
  <div>
    <sidebar-form
      :form-data-loading="metastatusLoading"
      :visible="showForm"
      :resource-id="resourceId"
      :status-options="statusOptions"
      :priority-options="priorityOptions"
      :category-options="categoryOptions"
      :source-options="sourceOptions"
      @saved="init"
      @close="onCloseForm"
    />
    <prozess-resource-view
      :resource="resource"
      :loading="loading"
      :fields="fields"
      :custom-fields="customFields"
      :can-update="$can('Update', 'Todo')"
      @edit="$can('Update', 'Todo') ? edit(resource.uuid) : null"
    >
      <template slot="body">
        <b-tabs
          content-class="mt-1"
          :value="tabs.indexOf(this.$route.params.tab)"
          @input="handleTabInput"
        >
          <b-tab>
            <template #title> <feather-icon icon="GridIcon" /> {{ $t('360 Overview') }} </template>
            <EntityOverviewWidgets
              :resource="resource"
              :field-labels="fields"
              schema-key="core"
              table-key="todo"
            />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="CheckSquareIcon" /> {{ $t('Documents') }} </template>
            <document-list
              entity-type="todo"
              schema-name="core"
              :with-edit-action="false"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="ClipboardIcon" /> {{ $t('Notes') }} </template>
            <notes
              schema-name="core"
              entity-type="todo"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="ListIcon" /> {{ $t('History') }} </template>
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              rounded="sm"
              variant="transparent"
              style="height: 100%"
              class="d-flex flex-column"
            >
              <div class="card">
                <div class="card-body mb-5">
                  <prozess-resource-history
                    :history="items"
                    :loading="loading"
                    :history-pagination="{
                      currentPage,
                      total,
                      perPage,
                    }"
                    @handleCurrentPage="handleCurrentPage"
                  />
                </div>
              </div>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </template>
    </prozess-resource-view>
  </div>
</template>

<script>
import { GenericTabs } from '@/constants/app'
import todoService from '@/services/todo'
import listMixinFactory from '@/mixins/list'
import SidebarForm from '@/views/pages/Todo/Form.vue'
import DocumentList from '@/views/pages/Documents/ListOnView/index.vue'
import RootEvents from '@/constants/rootEvents'
import Notes from '@/views/pages/Notes/ListOnView/index.vue'
import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import EntityOverviewWidgets from '@/components/EntityOverview/EntityOverviewWidgets.vue'

export default {
  components: {
    SidebarForm,
    DocumentList,
    Notes,
    EntityOverviewWidgets,
  },

  mixins: [
    metastatusDropdownMixin,
    listMixinFactory({
      service: todoService,
      isHistory: true,
    }),
  ],

  data() {
    return {
      loading: true,

      resource: {
        customFields: {},
      },

      fields: {
        title: 'title',
        companyName: 'company',
        contact: 'contact',
        statusName: 'status',
        priorityName: 'priority',
        categoryName: 'category',
        sourceName: 'source',
        assignedUserName: 'assignedUser',
      },

      customFields: {},
      tabs: [GenericTabs.OVERVIEW_360, GenericTabs.DOCUMENTS, GenericTabs.NOTES, GenericTabs.HISTORY],
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    handleTabInput(index) {
      this.$router
        .push({
          name: 'todo-view',
          params: {
            id: this.$route.params.id,
            tab: this.tabs[index] || null,
          },
        })
        .catch(() => {})
    },

    init() {
      this.$async(Promise.all([this.getMetastatusInitialData(), this.getDetails()]))
    },

    async getDetails() {
      const { response } = await this.$async(todoService.getOne(this.$route.params.id))

      const { data } = response

      const sortedCustomFields = this.$customFields.sort(data?.customFieldMetadata)

      this.customFields = sortedCustomFields.reduce((acc, field) => {
        acc[field.key] = field
        return acc
      }, {})

      this.resource = {
        ...data,
        contact: `${data.contactFirstName || ''} ${data.contactLastName}`,
        customFields: this.$customFields.formatForResource(data?.customFieldMetadata, data?.customFieldData),
      }

      this.$root.$emit(RootEvents.SET_PAGE_TITLE, this.resource.title)
    },
  },
}
</script>
